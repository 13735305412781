import DateTimeService from "./DateTimeService";

export default (function(apiLocation) {

    function constructEndpointURL(endpoint, data) {
        let url = apiLocation + endpoint;
        if(data) url += '?' + serialize(data);
        return url;
    }

    function serialize(obj, prefix) {
        const str = [];
        for(const p in obj) {
            if(!obj.hasOwnProperty(p)) continue;
            const k = prefix ? prefix + "[" + p + "]" : p, v = obj[p];
            if(v instanceof Date) {
                str.push(encodeURIComponent(k) + "=" + encodeURIComponent(DateTimeService.dateTimeFromDate(v)));
            } else if(v && typeof v === "object") {
                str.push(serialize(v, k));
            } else if(v === null) {
                str.push(encodeURIComponent(k) + "=");
            } else if(v === undefined) {}
            else {
                str.push(encodeURIComponent(k) + "=" + encodeURIComponent(v))
            }
        }
        return str.join("&");
    }

    function addDefaultHeaders(headers) {
        if(headers == null) headers = {};
        if(!headers.hasOwnProperty('Content-Type')) {
            headers['Content-Type'] = 'application/json';
        }
        return headers;
    }

    return {
        get: async function (endpoint, data, headers) {
            return await fetch(constructEndpointURL(endpoint, data), {
                method: 'GET',
                headers: addDefaultHeaders(headers)
            });
        },

        post: async function (endpoint, data, headers) {
            return await fetch(constructEndpointURL(endpoint), {
                method: 'POST',
                headers: addDefaultHeaders(headers),
                body: JSON.stringify(data)
            });
        },

        put: async function (endpoint, data, headers) {
            return await fetch(constructEndpointURL(endpoint), {
                method: 'PUT',
                headers: addDefaultHeaders(headers),
                body: JSON.stringify(data)
            });
        },

        patch: async function (endpoint, data, headers) {
            return await fetch(constructEndpointURL(endpoint), {
                method: 'PATCH',
                headers: addDefaultHeaders(headers),
                body: JSON.stringify(data)
            });
        },

        remove: async function remove(endpoint, headers) {
            return await fetch(constructEndpointURL(endpoint), {
                method: 'DELETE',
                headers: addDefaultHeaders(headers)
            });
        }
    }
})(process.env.VUE_APP_API_URL);