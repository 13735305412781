import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Datepicker from 'vue3-datepicker'

console.log(Datepicker);

createApp(App)
    .component('datepicker', Datepicker)
    .use(router)
    .mount('#app');