const DateTimeService = (function() {

    const datetimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/; //This regex matches YYYY-MM-DDTHH:MM:SS

    /* ----- Public Methods ----- */

    function isDateTime(val) {
        return datetimeRegex.test(val);
    }

    function isDate(val) {
        return val instanceof Date;
    }

    function convertDateTimesToDates(obj) {
        for (let p in obj) {
            if (!obj.hasOwnProperty(p)) continue;
            if (typeof obj[p] === 'object' && !obj[p] instanceof Date) convertDateTimesToDates(obj[p]);

            if (isDateTime(obj[p])) obj[p] = dateFromDateTime(obj[p]);
        }

        return obj;
    }

    function convertDatesToDateTimes(obj) {
        for (let p in obj) {
            if (!obj.hasOwnProperty(p)) continue;
            if (isDate(obj[p])) obj[p] = dateTimeFromDate(obj[p]);
        }

        return obj;
    }

    function dateFromDateTime(dateTime) {
        return new Date(dateTime + 'Z');
    }

    function dateTimeFromDate(date) {
        return date.toISOString().substr(0,19);
    }

    return {
        isDateTime: isDateTime,
        isDate: isDate,
        convertDateTimesToDates: convertDateTimesToDates,
        convertDatesToDateTimes: convertDatesToDateTimes,
        dateFromDateTime: dateFromDateTime,
        dateTimeFromDate: dateTimeFromDate,
        now: function() { return new Date(); }
    };
})();

export default DateTimeService;