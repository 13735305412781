export default (function(storage) {
    return {
        set: function(key, value) {
            storage[key] = value;
        },
        get: function(key, defaultValue) {
            return storage[key] || defaultValue;
        },
        setObject: function(key, value) {
            storage[key] = JSON.stringify(value);
        },
        getObject: function(key, defaultValue) {
            return JSON.parse(storage[key] || defaultValue || 'null');
        },
        remove: function(key) {
            storage.removeItem(key);
        }
    }
})(window.localStorage);