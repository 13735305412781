<template>
  <!-- BEGIN: Header-->
  <nav
      class="header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top navbar-dark navbar-shadow">
    <div class="navbar-wrapper">
      <div class="navbar-header">
        <ul class="nav navbar-nav flex-row">
          <li class="nav-item mobile-menu d-md-none mr-auto"><router-link class="nav-link nav-menu-main menu-toggle hidden-xs"
                                                                to="Home"><i class="ft-menu font-large-1"></i></router-link></li>
          <li class="nav-item">
            <router-link class="navbar-brand" to="Home">
              <h3 class="brand-text ">Bagby Airfield</h3>
              <!--<p class="text-muted"><small>Aircraft Monitoring System</small></p>-->
            </router-link>
          </li>
          <li class="nav-item d-md-none">
            <a class="nav-link open-navbar-container" data-toggle="collapse" data-target="#navbar-mobile"><i
                class="la la-ellipsis-v"></i></a>
          </li>
        </ul>
      </div>
      <div class="navbar-container content">
        <div class="collapse navbar-collapse" id="navbar-mobile">
          <ul class="nav navbar-nav mr-auto float-left">
            <li class="nav-item d-none d-md-block">
              <a class="nav-link nav-menu-main menu-toggle hidden-xs" href="#"><i class="ft-menu"></i></a>
            </li>
          </ul>
          <ul v-if="authenticatedUser" class="nav navbar-nav float-right">
            <li class="dropdown dropdown-user nav-item">
              <a class="dropdown-toggle nav-link dropdown-user-link" href="#" data-toggle="dropdown">
                <span class="mr-1 user-name text-bold-700">{{ authenticatedUser.username }}</span><span class="avatar">
                  <img src="./assets/images/avatar-blank.jpg" alt="avatar"></span>
              </a>
              <div class="dropdown-menu dropdown-menu-right">
                <!--<div class="dropdown-divider"></div>-->
                <a class="dropdown-item" href="#" @click="logout">
                  <i class="ft-power"></i> Logout
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
  <!-- END: Header-->

  <!-- BEGIN: Main Menu-->
  <MainMenu :authenticated-user="authenticatedUser"></MainMenu>
  <!-- END: Main Menu-->

  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
<!--          <h3 class="content-header-title">Title</h3>-->
        </div>
<!--        <div class="content-header-right col-md-6 col-12 mb-2">-->
<!--          <div class="row breadcrumbs-top">-->
<!--            <div class="breadcrumb-wrapper col-12">-->
<!--              <ol class="breadcrumb float-right">-->
<!--                <li class="breadcrumb-item"><router-link to="Home">Home</router-link>-->
<!--                </li>-->
<!--                <li class="breadcrumb-item active">Page name-->
<!--                </li>-->
<!--              </ol>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
      <div class="content-body">
        <router-view @user-authenticated="setAuthenticatedUser"></router-view>
      </div>
    </div>
  </div>
  <!-- END: Content-->

  <div class="sidenav-overlay"></div>
  <div class="drag-target"></div>
</template>

<style lang="scss">

.row-smaller-gutters {
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.row-smaller-gutters > div[class^="col"] {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.bg-standard {
  background-color: #f9f1d1;
}

.bg-emergency {
  background-color: #fbdddd;
}

/** Image modal **/

/* Style the Image Used to Trigger the Modal */
.modal-img {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

.modal-img:hover {
  opacity: 0.7;
}

/* The Modal (background) */
.modal-overlay {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 10000; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
}

/* Modal Content (Image) */
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

/* Caption of Modal Image (Image Text) - Same Width as the Image */
.modal-caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation - Zoom in the Modal */
.modal-content, .modal-caption {
  animation-name: zoom;
  animation-duration: 0.6s;
}

@keyframes zoom {
  from {
    transform: scale(0)
  }
  to {
    transform: scale(1)
  }
}

/* The Close Button */
.modal-close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.modal-close:hover,
.modal-close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}

/* Validation */
.is-invalid .invalid-feedback {
  display: block;
}



/* Cards */
.card-header {
  border-bottom: 1px solid #eee;
}

</style>

<script>

import MainMenu from "@/components/MainMenu";
import AuthService from "@/services/AuthService";

/** JQuery **/
// (function(window, undefined) {
//   'use strict';


  // $( document ).ready(function(){
  //   Event date picker stub
  //   const eventDatePicker =  $( ".datepicker-default" );
  //   eventDatePicker.datepicker("option", "dateFormat", "DD, d MM, yy");
  //   eventDatePicker.blur(function(){
  //     $('#event-timeline').removeClass('d-none');
  //   });
  //
  //   Event edit modals
  //   const eventEditButtons = $('.modal-edit-event');
  //   eventEditButtons.each(function(index, modalEventButtonHtml) {
  //     const modalEventButton = $(modalEventButtonHtml);
  //     const targetId = modalEventButton.attr('id');
  //     modalEventButton.on('click', function () {
  //       $(targetId).trigger('focus')
  //     });
  //   });
  //
  //   Event create modal
  //   const modalEventCreateButton = $('#event-create-button');
  //   const targetId = modalEventCreateButton.attr('id');
  //   modalEventCreateButton.on('click', function () {
  //     $(targetId).trigger('focus');
  //     const inputEventCreateDate = $('#event-create-date');
  //     inputEventCreateDate.val(($('#event-date-selected').val()));
  //   });
  //
  //   Event create form validation
  //   const eventCreateButton = $('#event-create-button-submit');
  //   eventCreateButton.click(function(){
  //     const timeInputGroup = $('#event-create-time');
  //     const hourInput = $('#event-create-time-hour');
  //     const minuteInput = $('#event-create-time-minute');
  //     if (
  //         (!hourInput.val() || hourInput.val() < 0 || hourInput.val() > 23) ||
  //         (!minuteInput.val() || minuteInput.val() < 0 || minuteInput.val() > 59)
  //     ){
  //       timeInputGroup.addClass('is-invalid');
  //     } else {
  //       timeInputGroup.removeClass('is-invalid');
  //     }
  //   });

//     const substringMatcher = function(strs) {
//       return function findMatches(q, cb) {
//         var matches, substrRegex;
//
//         // an array that will be populated with substring matches
//         matches = [];
//
//         // regex used to determine if a string contains the substring `q`
//         substrRegex = new RegExp(q, 'i');
//
//         // iterate through the pool of strings and for any string that
//         // contains the substring `q`, add it to the `matches` array
//         $.each(strs, function(i, str) {
//           if (substrRegex.test(str)) {
//             matches.push(str);
//           }
//         });
//
//         cb(matches);
//       };
//     };
//
//     const residents = [
//       'G-AAAA',
//       'G-AAAB',
//       'G-AAAC',
//       'G-AAAD'
//     ];
//
//     $('.resident-wrapper').each(function(index, residentWrapperHtml){
//       const residentWrapper = $(residentWrapperHtml);
//       const residentCheckbox = residentWrapper.find('.resident-checkbox');
//       const residentTypeahead = residentWrapper.find('.resident-typeahead');
//       const residentValidCreateHint = residentWrapper.find('.resident-valid-create-hint');
//
//       residentCheckbox.on('click', function(index){
//         if (residentCheckbox.is(':checked')){
//           residentTypeahead.removeClass('d-none');
//         } else {
//           residentTypeahead.addClass('d-none');
//         }
//       });
//
//       $('.resident-typeahead .typeahead-basic').typeahead(
//           {
//             hint: true,
//             highlight: true,
//             minLength: 1
//           },
//           {
//             name: 'residents',
//             source: substringMatcher(residents)
//           }
//       ).on("input", function(e) {
//
//         const residentTypeaheadInput = $(e.target);
//         const regex = /^[A-Z]{1}-[A-Z]{4}$/;
//         console.log('residentTypeaheadInput.val()', residentTypeaheadInput.val());
//
//         if(regex.test(residentTypeaheadInput.val())){
//
//           residentTypeahead.removeClass('is-invalid');
//           residentValidCreateHint.removeClass('d-none');
//           residentTypeaheadInput.on('keypress',function(e) {
//
//             if (e.which === 13) {
//               const confirmation = confirm('Create new resident?');
//
//               if(confirmation){
//                 console.log('Create resident here');
//                 residentValidCreateHint.removeClass('d-none');
//               }
//             }
//           });
//
//         } else {
//           residentTypeahead.addClass('is-invalid');
//           residentValidCreateHint.addClass('d-none');
//         }
//
//       });
//     });
//
//   });
//
// })(window);

export default {
  components: {MainMenu},
  data () {
    return {
      publicPath: process.env.BASE_URL,
      authenticatedUser: null
    }
  },
  methods: {
    async logout() {
      await AuthService.clearAuthentication();
      this.setAuthenticatedUser(null);
      await this.$router.push('/Login');
    },
    setAuthenticatedUser(user) {
      this.authenticatedUser = user;
    }
  },
  async beforeCreate() {
    this.authenticatedUser = await AuthService.getAuthenticatedUser();
  }
}
</script>
