import { createRouter, createWebHistory } from 'vue-router'
import AuthService from "@/services/AuthService";

const routes = [
    { path: '/', redirect: '/Home' },
    {
        path:      '/Home',     //TODO: figure out why '/' here doesn't load the Home component?
        name:      'Home',
        component: () => import('../views/Home.vue')
    },
    {
        path:      '/login',
        name:      'Login',
        component: () => import('../views/Login.vue')
    },
    {
        path:      '/movements',
        name:      'Movements',
        component: () => import('../views/Movements.vue')
    },
    {
        path:      '/users',
        name:      'Users',
        component: () => import('../views/Users.vue')
    },
    {
        path:      '/residents',
        name:      'Residents',
        component: () => import('../views/Residents.vue')
    },
    {
        path:      '/aircraft',
        name:      'Aircraft',
        component: () => import('../views/Aircraft.vue')
    },
    {
        path:      '/calendar',
        name:      'Calendar',
        component: () => import('../views/Calendar.vue')
    },
    {
        path:      '/auditlog',
        name:      'AuditLog',
        component: () => import('../views/AuditLog.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkExactActiveClass: 'active',
    routes
});

router.beforeResolve(async (to, from, next) => {
    if (to.name !== 'Login' && !await AuthService.isAuthenticated()) {
        next({ name: 'Login', query: {redirect: to.path} });
    } else {
        next();
    }
});

export default router
