import LocalStorageService from "./LocalStorageService";
import HTTPService from "./HTTPService";
import jwt_decode from "jwt-decode"

export const ROLE_SUPER_ADMIN = 'super_admin';
export const ROLE_ADMIN = 'admin';
export const ROLE_MONITOR = 'monitor';

export default (function() {

    function cacheCredentials(username, password) {
        LocalStorageService.setObject('credentials', {username: username, password: password});
    }

    function retrieveCredentials() {
        return LocalStorageService.getObject('credentials');
    }

    function cacheAuthToken(token) {
        LocalStorageService.set('token', token)
    }

    function retrieveAuthToken() {
        return LocalStorageService.get('token');
    }

    function tokenHasExpired(token) {
        const decoded = jwt_decode(token);
        const now = new Date();
        const expiry = new Date(decoded.exp * 1000);
        return expiry < now;
    }

    async function refreshAuthToken(username, password) {
        const response = await HTTPService.post(
            'auth',
            {username: username, password: password}
        );
        if(!response.ok) {
            throw new Error(await response.json());
        }
        const token = await response.json();
        cacheAuthToken(token);
        return token;
    }

    async function getAuthToken() {
        const token = retrieveAuthToken();
        if(
            token !== null
            && !tokenHasExpired(token)
        ) {
            return token;
        }

        const credentials = retrieveCredentials();
        if(
            credentials !== null
            && credentials.username != null
            && credentials.password != null
        ) {
            return await refreshAuthToken(credentials.username, credentials.password);
        }

        throw new Error("User is not authenticated!");
    }

    async function isAuthenticated() {
        try {
            await getAuthToken();
        } catch(e) {
            return false;
        }
        return true;
    }

    /**
     * @typedef { Object } UserToken
     * @property { number } exp
     * @property { number } iat
     * @property { string } iss
     * @property { string[] } roles
     * @property { number } uid
     * @property { string } username
     */

    /**
     * @returns {Promise<UserToken>}
     */
    async function getAuthenticatedUser() {
        if(!await isAuthenticated()) return null;
        return jwt_decode(await getAuthToken());
    }

    return {
        authenticate: async function(username, password) {
            const token = await refreshAuthToken(username, password);
            cacheCredentials(username, password);
            return jwt_decode(token);
        },

        clearAuthentication: function() {
            LocalStorageService.remove('credentials');
            LocalStorageService.remove('token');
        },

        isAuthenticated: isAuthenticated,
        getAuthenticationToken: getAuthToken,
        getAuthenticatedUser: getAuthenticatedUser,

        hasRole: async function(role) {
            if(!(await isAuthenticated())) return false;
            const user = await getAuthenticatedUser();
            return user.roles.includes(role);
        }
    }
})();
