<template>
  <div class="main-menu menu-fixed menu-dark menu-accordion menu-shadow" data-scroll-to-active="true">
    <div class="main-menu-content">
      <ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
        <li class=" nav-item">
          <router-link to="Home"><i class="la la-home"></i><span class="menu-title">Dashboard</span></router-link>
        </li>
        <li class=" nav-item">
          <router-link to="Movements"><i class="la la-plane"></i><span class="menu-title">Movements</span></router-link>
        </li>
        <li class="nav-item" v-if="showAdminMenu">
          <a href="#"><i class="la la-cog"></i><span class="menu-title">Admin</span></a>
          <ul class="menu-content">
            <router-link custom to="AuditLog" v-slot="{ href, navigate, isActive }">
              <li :class="[isActive ? 'active' : '', 'nav-item']">
                <a :href="href" @click="navigate">Audit Log</a>
              </li>
            </router-link>

            <router-link custom to="Residents" v-slot="{ href, navigate, isActive }">
              <li :class="[isActive ? 'active' : '', 'nav-item']">
                <a :href="href" @click="navigate">Resident aircraft</a>
              </li>
            </router-link>

            <router-link custom to="Aircraft" v-slot="{ href, navigate, isActive }">
              <li :class="[isActive ? 'active' : '', 'nav-item']">
                <a :href="href" @click="navigate">Registered aircraft types</a>
              </li>
            </router-link>

            <router-link custom to="Calendar" v-slot="{ href, navigate, isActive }">
              <li :class="[isActive ? 'active' : '', 'nav-item']">
                <a :href="href" @click="navigate">Calendar</a>
              </li>
            </router-link>

<!--            <li>-->
<!--              <router-link class="menu-item" to="#">Event Thresholds</router-link>-->
<!--            </li>-->
            <router-link custom to="Users" v-slot="{ href, route, navigate, isActive, isExactActive }">
              <li :class="[isActive ? 'active' : '', 'nav-item']">
                <a :href="href" @click="navigate">Users</a>
              </li>
            </router-link>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ROLE_SUPER_ADMIN, ROLE_ADMIN } from "@/services/AuthService";

export default {
  name: "MainMenu",
  props: {
    authenticatedUser: {
      type: Object,
      required: false
    }
  },
  computed: {
    showAdminMenu: function() {
      return this.authenticatedUser?.roles.includes(ROLE_ADMIN)
          || this.authenticatedUser?.roles.includes(ROLE_SUPER_ADMIN)
    }
  }
}
</script>
